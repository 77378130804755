#loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #lottie {
        width: 25%;
        height: 25%;
    }
}

@media screen and (max-width: 600px) {
    #loader {

        #lottie {
            width: 85%;
            height: 85%;
        }
    }
}